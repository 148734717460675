import { DebugProvider } from "helpers/debug";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import { CloudinaryProvider } from "react-tiny-cloudinary";

function MyApp({ Component, pageProps, err }: AppProps & { err: any }) {
    // Workaround to conditionally load global css.
    useEffect(() => {
        process.env.NEXT_PUBLIC_SITE_THEME === "schaub"
            ? require("../styles/schaub/globals.scss")
            : require("../styles/emtek/globals.scss");

        require("../styles/globals.scss");
    }, []);

    // Workaround for https://github.com/vercel/next.js/issues/8592
    return (
        <DebugProvider>
            <CloudinaryProvider
                cloudName={process.env.NEXT_PUBLIC_CLOUDINARY_NAME || "emtek"}
                defaultTransformation={{
                    defaultImage: "product-placeholder.jpg",
                    dpr: "auto",
                    fetchFormat: "auto",
                    quality: "auto",
                }}
            >
                <Component {...pageProps} err={err} />
            </CloudinaryProvider>
        </DebugProvider>
    );
}

export default MyApp;
